@tailwind base;
@tailwind components;
/* @font-face {
  font-family: 'Cairo';
  src: local('Cairo'), url(./fonts/Roboto-Regular.ttf) format('ttf');
} */
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Tajawal', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 background-color: #F4F7FE;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.fullscreen {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /* extra fullscreen guarantees - you might not need the rest */
  position: absolute;
  top: 0;
  left: 0;
}

.react-datepicker{
  right: 0px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.apexcharts-legend-marker {
  left: 5px !important;
}

.apexcharts-legend-text{
  font-family: 'Tajawal', sans-serif !important;
  font-size: 14px !important;
}

/* .apexcharts-xaxis-label tspan {
transform: rotate(-6deg) !important;
} */

 .react-datepicker-popper{
  position: relative !important;
  inset: 0px !important;
  transform: none !important;
  z-index: 10000 !important;
 }

 .apexcharts-legend{
   /* width: 160px !important; */
 }